import styled from "styled-components"

export const ContainerWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 4.2rem)) 1fr;
  grid-template-rows: 4rem 20rem 4rem auto;
  /* grid-template-rows: 4rem 80vh 4rem auto; */
  gap: 0 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 2rem repeat(8, 1fr) 2rem;
    gap: 0 1rem;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-template-rows: 4rem 8rem 4rem auto;
    grid-template-columns: 0.3rem repeat(6, 1fr) 0.3rem;
  }
`

export const ContainerWrapper70 = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 4.2rem)) 1fr;
  grid-template-rows: 4rem 20vh 50vh 4rem auto;
  gap: 0 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 2rem repeat(8, 1fr) 2rem;
    gap: 0 1rem;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-template-rows: 4rem 8rem 4rem auto;
    grid-template-columns: 0.3rem repeat(6, 1fr) 0.3rem;
  }
`

export const ContainerWrapper100 = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 4.2rem)) 1fr;
  grid-template-rows: 4rem 10vh 90vh 4rem auto;
  gap: 0 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 2rem repeat(8, 1fr) 2rem;
    gap: 0 1rem;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-template-rows: 4rem 8rem 4rem auto;
    grid-template-columns: 0.3rem repeat(6, 1fr) 0.3rem;
  }
`

export const FixedContainerWrapper100 = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 4.2rem)) 1fr;
  grid-template-rows: 8rem calc(100vh - 8rem);
  gap: 0 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 2rem repeat(8, 1fr) 2rem;
    gap: 0 1rem;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-template-columns: 0.3rem repeat(6, 1fr) 0.3rem;
    grid-template-rows: 6rem calc(100vh - 8rem);
  }
`
