import styled from "styled-components"

export const FeatureImageWrapper = styled.div`
  grid-column: 2 / span 12;
  grid-row: 2 / 3;
  overflow: hidden;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    content: "${(props) => (props.category ? props.category : "")}";
    font-size: 2rem;
    font-weight: 700;
    color: white;

    @media ${(props) => props.theme.breakpoints.mobile} {
      font-size: 1.5rem;
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 8;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1 / span 8;
  }
`

export const FeatureImageFullWrapper = styled.div`
  grid-column: 1 / span 14;
  grid-row: 1 / 3;
  overflow: hidden;
  position: relative;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-column: 1 / span 10;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1 / span 8;
  }
`
