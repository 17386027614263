import React from "react"
import { FooterSocialWrapper, FooterWrapper, P } from "../elements"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.main1};
  font-weight: 500;
`

export function Footer() {
  // const data = useStaticQuery(graphql`
  //   query {
  //     facebook: file(relativePath: { eq: "facebook.svg" }) {
  //       publicURL
  //     }
  //     instagram: file(relativePath: { eq: "instagram.svg" }) {
  //       publicURL
  //     }
  //     pinterest: file(relativePath: { eq: "pinterest.svg" }) {
  //       publicURL
  //     }
  //   }
  // `)
  return (
    <FooterWrapper>
      <FooterSocialWrapper>
        {/* <FooterSocialIcons> */}
        {/* <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={data.facebook.publicURL} alt="Facebook logo" />
          </a> */}
        {/* <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={data.instagram.publicURL} alt="Instagram logo" />
          </a> */}
        {/* <a
            href="https://pinterest.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={data.pinterest.publicURL} alt="Pinterest logo" />
          </a> */}
        {/* </FooterSocialIcons> */}
        <P margin="0" size="small" color="dark1">
          <StyledLink to="/impressum">Impressum</StyledLink> |{" "}
          <StyledLink to="/datenschutz">Datenschutzerklärung</StyledLink>
        </P>
        <P size="xSmall" color="dark1" weight="medium" margin="0">
          &copy;{` `}
          {new Date().getFullYear()} <span>Tom Wolf</span>
        </P>
      </FooterSocialWrapper>
    </FooterWrapper>
  )
}
