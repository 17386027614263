import React from "react"
import { motion } from "framer-motion"
import {
  ContentWrapperPure,
  ContentWrapperTransparent,
  ContentWrapperTransparentInner
} from "../elements"

export function Content({ children, bg }) {
  return bg === "transparent" ? (
    <ContentWrapperTransparent>
      <ContentWrapperTransparentInner>
        <motion.main
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1
          }}
          exit={{
            opacity: 0
          }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3
          }}
        >
          {/* <motion.main
          initial={{
            opacity: 0,
            x: -200
          }}
          animate={{
            opacity: 1,
            x: 0
          }}
          exit={{
            opacity: 0,
            x: 200
          }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.3
          }}
        > */}
          {children}
        </motion.main>
      </ContentWrapperTransparentInner>
    </ContentWrapperTransparent>
  ) : (
    <ContentWrapperPure>
      <motion.main
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        exit={{
          opacity: 0
        }}
        transition={{
          type: "spring",
          mass: 0.35,
          stiffness: 75,
          duration: 0.3
        }}
      >
        {children}
      </motion.main>
    </ContentWrapperPure>
  )
}
