import React from "react"
import Gallery from "@browniebroke/gatsby-image-gallery"

import { ImageGalleryWrapper } from "../elements"

// const CustomWrapper = ({ children, onClick }) => {
//   const [{ y, color }, set] = useSpring(() => ({ y: 100, color: "#fff" }))
//   return (
//     <>
//       <CustomGalleryWrapper
//         onClick={onClick}
//         onMouseEnter={() => set({ y: 0, color: "#000" })}
//         onMouseLeave={() => set({ y: 100, color: "#fff" })}
//       >
//         <animated.div style={{ color }} className="wrapDiv">
//           {children}
//         </animated.div>
//       </CustomGalleryWrapper>
//       <animated.div
//         style={{ transform: y.to((v) => `translateY(${v}%`) }}
//         className="glance"
//       />
//     </>
//   )
// }

export function ImageGallery({ data }) {
  const images = data.contentfulFotogalerie.fotos.map((foto) => {
    if (!foto.thumbAlt) {
      foto.thumbAlt = "Tom Wolf Photografie"
    }
    if (!foto.title) {
      foto.title = "Tom Wolf Photografie"
    }
    return foto
  })

  return (
    <ImageGalleryWrapper>
      {/* <Gallery images={images} customWrapper={CustomWrapper} /> */}
      <Gallery
        images={images}
        mdColWidth="33"
        colWidth="50"
        imgClass="img-pointer"
      />
    </ImageGalleryWrapper>
  )
}
