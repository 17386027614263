import styled from "styled-components"

export const ContentWrapper = styled.main`
  grid-column: 3 / span 10;
  grid-row: 2 / 3;
  background-color: rgba(65, 65, 65, 0.8);
  backdrop-filter: blur(5px);
  /* background-color: ${(props) => props.theme.colors.light2}; */
  padding: ${(props) =>
    `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
  box-shadow: ${(props) => props.theme.shadows.shadow1};
  z-index: 10;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-column: 3 / span 6;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 2 / span 6;
    grid-row: 2 / 3;
    /* margin-top: -1rem; */
    padding: ${(props) =>
      `${props.theme.spacings.large} ${props.theme.spacings.medium}`};
  }
`

export const ContentWrapperTransparent = styled.main`
  grid-column: 3 / span 10;
  grid-row: 2 / 3;

  z-index: 10;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-column: 3 / span 6;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 2 / span 6;
    grid-row: 2 / 3;
    padding: ${(props) =>
      `${props.theme.spacings.small} ${props.theme.spacings.xxSmall}`};
  }
`

export const ContentWrapperTransparentInner = styled.div`
  background-color: rgba(168, 168, 168, 0.25);
  backdrop-filter: blur(5px);
  padding: ${(props) =>
    `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
  box-shadow: ${(props) => props.theme.shadows.shadow1};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: ${(props) =>
      `${props.theme.spacings.large} ${props.theme.spacings.large}`};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: ${(props) =>
      `${props.theme.spacings.medium} ${props.theme.spacings.medium}`};
  }
`

export const ContentWrapperPure = styled.main`
  grid-column: 3 / span 10;
  grid-row: 2 / 3;
  padding: ${(props) =>
    `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
  box-shadow: ${(props) => props.theme.shadows.shadow1};
  z-index: 10;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-column: 3 / span 6;
    padding: ${(props) =>
      `${props.theme.spacings.xLarge} ${props.theme.spacings.small}`};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 2 / span 6;
    grid-row: 2 / 3;
    padding: ${(props) =>
      `${props.theme.spacings.small} ${props.theme.spacings.xxSmall}`};
  }
`
// export const ContentWrapperTransparent = styled.main`
//   grid-column: 3 / span 10;
//   grid-row: 3 / span 1;
//   background-color: rgba(168, 168, 168, 0.25);
//   backdrop-filter: blur(5px);
//   padding: ${(props) =>
//     `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
//   box-shadow: ${(props) => props.theme.shadows.shadow1};
//   z-index: 10;

//   @media ${(props) => props.theme.breakpoints.tablet} {
//     grid-column: 3 / span 6;
//   }

//   @media ${(props) => props.theme.breakpoints.mobile} {
//     grid-column: 2 / span 6;
//     grid-row: 4 / span 1;
//     /* margin-top: -1rem; */
//     padding: ${(props) =>
//       `${props.theme.spacings.large} ${props.theme.spacings.medium}`};
//   }
// `

export const CategoryHeading = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 2rem;
`

export const ContentBackground = styled.div`
  background-color: rgba(65, 65, 65, 0.2);
  backdrop-filter: blur(5px);
  padding: ${(props) =>
    `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
  box-shadow: ${(props) => props.theme.shadows.shadow1};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: ${(props) =>
      `${props.theme.spacings.xLarge} ${props.theme.spacings.large}`};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: ${(props) =>
      `${props.theme.spacings.small} ${props.theme.spacings.small}`};
  }
`

export const ContentBackgroundTransparent = styled.div`
  background-color: rgba(168, 168, 168, 0.25);
  backdrop-filter: blur(5px);
  /* padding: ${(props) =>
    `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`}; */
  box-shadow: ${(props) => props.theme.shadows.shadow1};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: ${(props) =>
      `${props.theme.spacings.xLarge} ${props.theme.spacings.large}`};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: ${(props) =>
      `${props.theme.spacings.small} ${props.theme.spacings.small}`};
  }
`
