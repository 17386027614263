import styled from "styled-components"
import { MaxWidthSection } from "./MaxWidthSectionElements"

export const NavWrapper = styled(MaxWidthSection)`
  grid-row: 1 / 2;
  padding: 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 21;

  img {
    height: 30px;
    display: block;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    img {
      height: 25px;
    }
  }
`

export const LinksMenu = styled.div`
  display: none;
  height: 100%;
  align-items: center;

  .active-link {
    border-top: 2px ${(props) => props.theme.colors.blue1} solid;
  }

  a {
    text-decoration: none;
    color: white;
    /* color: ${(props) => props.theme.colors.main2}; */
    border-top: 2px transparent solid;
    transition: ${(props) => props.theme.animations.navLink};
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-weight: 600;
    font-size: 1rem;
    margin-left: 1rem;

    &:hover {
      border-top: 2px ${(props) => props.theme.colors.blue1} solid;
    }
  }

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`

export const LinksContainer = styled.div`
  position: relative;
`

export const LinkWithSubmenuContainer = styled.div`
  .submenu {
    display: none;
    position: absolute;
    top: 17px;
    left: 0px;
    min-width: 150px;
    /* background-color: ${(props) => props.theme.colors.light2}; */
    background-color: transparent;
    z-index: 30;

    a {
      border: none;
      margin: 0;
      padding: 0.7rem;
      text-align: left;
      background-color: rgba(80, 80, 80, 0.6);
      backdrop-filter: blur(5px);
      /* background-color: ${(props) => props.theme.colors.light2}; */
    }

    a:hover {
      border: none;
      background-color: rgba(80, 80, 80, 0.8);
      /* background-color: ${(props) => props.theme.colors.light1}; */
    }
  }

  &:hover .submenu {
    display: block;
    padding: 1rem 0 0;
  }
`

export const SubmenuContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadows.shadow1};
  display: flex;
  flex-direction: column;
`
