import React, { useEffect, useRef, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { IoCaretUpCircleSharp, IoCaretDownCircleSharp } from "react-icons/io5"
import { useSpring, config, animated } from "react-spring"

import { useGlobalContext } from "../context/context"
import SidebarSubmenu from "./SidebarSubmenu"
import { IconButton, LinkWithDropdown } from "../elements"
// import { useHeight } from "../hooks/useHeight"

export default function SidebarLink({ link }) {
  const { closeSidebar, isSubmenuOpen, toggleSubmenu } = useGlobalContext()

  const [height, setHeight] = useState(null)

  const heightRef = useRef(null)

  useEffect(() => {
    if (isSubmenuOpen) {
      setHeight(heightRef.current.getBoundingClientRect().height)
      heightRef.current.style.height = `${height}px`
    }
  }, [isSubmenuOpen, height])

  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: isSubmenuOpen ? 1 : 0,
      height: isSubmenuOpen ? height : 0
    }
  })

  const data = useStaticQuery(graphql`
    query {
      gallery: allContentfulFotogalerie(
        sort: { fields: kategorie, order: ASC }
      ) {
        nodes {
          id
          kategorie
        }
      }
    }
  `)

  return (
    <>
      {link.submenu ? (
        <>
          <LinkWithDropdown>
            <Link to={link.target} onClick={closeSidebar}>
              {link.label}
            </Link>
            <IconButton
              size="1.4rem"
              margin="0 0 0 0.5rem"
              onClick={toggleSubmenu}
            >
              {isSubmenuOpen ? (
                <IoCaretUpCircleSharp />
              ) : (
                <IoCaretDownCircleSharp />
              )}
            </IconButton>
          </LinkWithDropdown>

          <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
            <div ref={heightRef}>
              <SidebarSubmenu submenuLinks={data?.gallery?.nodes} />
            </div>
          </animated.div>
        </>
      ) : (
        <>
          <Link to={link.target} onClick={closeSidebar}>
            {link.label}
          </Link>
          <div ref={heightRef}></div>
        </>
      )}
    </>
  )
}
