import styled from "styled-components"

export const ImageGalleryWrapper = styled.div`
  line-height: 0;

  .img-pointer {
    cursor: pointer;
    /* transition: var(--transition); */

    &:hover {
      /* filter: drop-shadow(0 0 10px #797979); */
    }
  }
`

export const CustomGalleryWrapper = styled.div`
  flex-basis: 33%;

  img {
    cursor: pointer;
  }

  .wrapDiv {
    z-index: 2;
    position: relative;
  }

  .glance {
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateY(50%);
  }

  @media screen and (max-width: 360px) {
    flex-basis: 100%;
  }
`
