import React from "react"
import { ButtonWrapper } from "../elements"

export function Button({ children, to, margin, secondary }) {
  return (
    <ButtonWrapper to={to} margin={margin} secondary={secondary}>
      {children}
    </ButtonWrapper>
  )
}
