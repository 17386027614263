import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  CategoriesWrapper,
  CategoriesContainer,
  CategoriesTitle
} from "../elements"
import { Link } from "gatsby"

export function Categories({ data }) {
  return (
    <CategoriesWrapper>
      {data.allContentfulFotogalerie.nodes.map(({ id, kategorie, fotos }) => {
        if (fotos === null) return null

        const image = getImage(fotos[0].gatsbyImageData)
        const category = kategorie.toLowerCase()

        return (
          <Link to={`/bilder/${category}`} key={id}>
            <CategoriesContainer>
              <GatsbyImage
                image={image}
                alt={fotos[0]?.description || "Tom Wolf Photografie"}
                imgClassName="category-image"
              />

              <CategoriesTitle>
                <h3>{kategorie}</h3>
              </CategoriesTitle>
            </CategoriesContainer>
          </Link>
        )
      })}
    </CategoriesWrapper>
  )
}
