import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { FeatureImageFullWrapper } from "../elements"

export function FeatureImage({ category }) {
  return (
    <FeatureImageFullWrapper category={category}>
      <StaticImage
        src="../assets/images/palmen01.jpg"
        alt="Pflanzen im Frankfurter Palmengarten"
        placeholder="blurred"
        layout="fullWidth"
        style={{ height: "100%" }}
        imgStyle={{
          filter: "brightness(70%) grayscale(80%)",
          position: "fixed"
        }}
      />
    </FeatureImageFullWrapper>
  )
}
