import styled from 'styled-components'

export const MegaMenuWrapper = styled.div`
  @media only screen and (min-width: 768px) {
    position: relative;

    .content {
      position: absolute;
      display: none;
      top: 16px;
      left: -50px;
      width: 200px;
      background-color: transparent;
      z-index: 1;
    }

    &:hover > .content {
      display: block;
      padding: 1rem 2rem;
    }
  }
`

export const MegaMenuLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 0.25rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);

  a {
    text-decoration: none;
    font-weight: 400;
    color: var(--fontPrimary);

    &:hover {
      color: var(--clrFontAccent);
    }
  }

  li {
    margin: 1rem 0;
  }
`
