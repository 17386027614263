import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const ButtonWrapper = styled((props) => <Link {...props} />)`
  display: inline-block;
  padding: 0.5rem 0.75rem;
  background-color: ${(props) =>
    props.secondary ? props.theme.colors.blue1 : props.theme.colors.main2};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  border-radius: 3px;
  color: ${(props) =>
    props.secondary ? "#FFFFFF" : props.theme.colors.light1};
  font-weight: 500;
  font-size: 1rem;
  width: fit-content;
  transition: filter 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    filter: brightness(110%);
  }
`

export const IconButton = styled.button`
  cursor: pointer;
  font-size: ${(props) => (props.size ? props.size : "1.75rem")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  background-color: transparent;
  border-color: transparent;
  color: ${(props) =>
    props.color === "white" ? "white" : props.theme.colors.dark1};
  display: flex;
`

export const ButtonOutline = styled((props) => <Link {...props} />)`
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  transition: ${(props) => props.theme.animations.general};

  &:hover {
    color: ${(props) => props.theme.colors.dark3};
    background-color: white;
  }
`
