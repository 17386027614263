export const LINKS = [
  {
    id: 0,
    target: "/home",
    label: "Home"
  },
  {
    id: 1,
    target: "/kategorien",
    label: "Fotos",
    submenu: "kategorien"
  },
  {
    id: 2,
    target: "/about",
    label: "Über mich"
  },
  {
    id: 3,
    target: "/kontakt",
    label: "Kontakt"
  }
]
