import React from "react"
import { ThemeProvider } from "styled-components"

import { AppProvider } from "./src/context/context"
import theme from "./src/themes/theme"
import { GlobalStyles } from "./src/elements"
import { Container } from "./src/components"

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  </AppProvider>
)

export const wrapPageElement = ({ element, props }) => {
  return props.location.pathname === "/" ? (
    element
  ) : (
    <Container>{element}</Container>
  )
}
