import styled from "styled-components"

export const LandingContainerWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(14, minmax(auto, 4.2rem));
  grid-template-rows: 4rem 50rem;
  /* grid-template-rows: 7.8rem 20rem 4rem auto; */
  gap: 0 2rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    grid-template-columns: 2rem repeat(8, 1fr) 2rem;
    gap: 0 1rem;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-template-rows: 4rem 8rem 4rem auto;
    grid-template-columns: 0.3rem repeat(6, 1fr) 0.3rem;
  }
`
