import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    font-family: ${(props) => props.theme.fonts.main};
    height: 100%;
    background-color: ${(props) => props.theme.colors.dark3};
    margin: 0;
    padding: 0;
  }

  h1, h2 {
    font-weight: 900;
    color: ${(props) => props.theme.colors.main1};
  }

  p {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .my-dots {
    bottom: 20px;
    z-index: 2;

    button::before {
      color: white !important;
    }
  }
`
