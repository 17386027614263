import React from "react"
import styled from "styled-components"

// import { Nav } from "./"

const HeaderContainer = styled.div`
  height: 100vh;
  position: relative;

  .hero-img {
    height: 100%;
  }
`

export function LandingContainer({ children }) {
  return (
    <HeaderContainer>{children}</HeaderContainer>
    // <LandingContainerWrapper>
    //   <Nav />
    //   <FullWidthSection>{children}</FullWidthSection>
    // </LandingContainerWrapper>
  )
}
