import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { FaBars } from "react-icons/fa"
import { motion, AnimatePresence } from "framer-motion"

import { useGlobalContext } from "../context/context"
import { IconButton, NavWrapper } from "../elements"
import Sidebar from "./Sidebar"

export function Nav() {
  const { toggleSidebar, isSidebarOpen } = useGlobalContext()

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <NavWrapper>
      <Link to="/">
        <img src={data.file.publicURL} alt="Tom Wolf Logo" />
      </Link>
      <AnimatePresence>
        {!isSidebarOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <IconButton onClick={toggleSidebar} color="white">
              <FaBars />
            </IconButton>
          </motion.div>
        )}
      </AnimatePresence>

      <Sidebar />
    </NavWrapper>
  )
}
