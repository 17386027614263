import React from "react"

import { FixedContainerWrapper100 } from "../elements"
import { Nav } from "./"
import { FeatureImage } from "./FeatureImage"

export function Container({ children }) {
  return (
    <FixedContainerWrapper100>
      <Nav />
      <FeatureImage />
      {children}
    </FixedContainerWrapper100>
  )
}
