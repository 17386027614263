import styled from "styled-components"

export const CategoriesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* grid-auto-rows: 16vw; */
  grid-gap: 1rem;
  /* margin: 0 auto;
  width: 100%; */

  /* & > :nth-child(6n + 3) {
    grid-column: span 1;
    grid-row: span 2;
  }

  & > :nth-child(6n + 2),
  & > :nth-child(6n + 5),
  & > :nth-child(6n + 6) {
    grid-column: span 2;
    grid-row: span 2;
  } */

  @media screen and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const CategoriesContainer = styled.div`
  position: relative;
  cursor: pointer;
  transition: var(--transition);

  .category-image {
    transition: ${(props) => props.theme.animations.general};
    filter: brightness(70%);
    /* object-fit: cover; */
  }

  &:hover {
    box-shadow: var(--shadow-2);

    .category-image {
      filter: brightness(90%);
    }
  }
`

export const CategoriesTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    color: ${(props) => props.theme.colors.light1};
    margin: 0;
    font-size: 1.3rem;
    font-weight: 500;
  }
`
