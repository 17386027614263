import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { FaTimes } from "react-icons/fa"

import { useGlobalContext } from "../context/context"
import {
  IconButton,
  SidebarFooter,
  SidebarFooterPolicyLinks,
  SidebarFooterWrapper,
  SidebarHeader,
  SidebarLinksWrapper,
  SidebarWrapper
} from "../elements"
import SidebarLink from "./SidebarLink"
import { LINKS } from "../constants/links"

const Sidebar = () => {
  const { isSidebarOpen, closeSidebar } = useGlobalContext()

  Sidebar.handleClickOutside = () => closeSidebar()

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      facebook: file(relativePath: { eq: "facebook.svg" }) {
        publicURL
      }
      instagram: file(relativePath: { eq: "instagram_white.svg" }) {
        publicURL
      }
      pinterest: file(relativePath: { eq: "pinterest.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <SidebarWrapper isSidebarOpen={isSidebarOpen}>
      <SidebarHeader>
        {/* <Link to="/" onClick={closeSidebar}>
          <img src={data.file.publicURL} alt="Tom Wolf Logo" />
        </Link> */}
        <IconButton onClick={closeSidebar} color="white">
          <FaTimes />
        </IconButton>
      </SidebarHeader>

      <SidebarLinksWrapper>
        {LINKS.map((link) => (
          <SidebarLink link={link} key={link.id} />
        ))}
      </SidebarLinksWrapper>
      <SidebarFooterWrapper>
        <SidebarFooter>
          {/* <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.facebook.publicURL} alt="Facebook logo" />
        </a> */}
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={data.instagram.publicURL}
              alt="Instagram logo"
              fill="#FFF"
            />
          </a>
          {/* <a
          href="https://pinterest.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.pinterest.publicURL} alt="Pinterest logo" />
        </a> */}
        </SidebarFooter>
        <SidebarFooterPolicyLinks>
          <Link to="/impressum" onClick={closeSidebar}>
            Impressum
          </Link>
          <Link to="/datenschutz" onClick={closeSidebar}>
            Datenschutzerklärung
          </Link>
        </SidebarFooterPolicyLinks>
      </SidebarFooterWrapper>
    </SidebarWrapper>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => Sidebar.handleClickOutside
}

export default Sidebar
