import styled from 'styled-components'

export const HeroWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;

  .img-shadow {
    box-shadow: var(--shadow-2);
    border-radius: var(--borderRadius);
  }
`

export const HeroContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius);
`

export const HeroHeading = styled.h1`
  color: var(--clrWhite);
  text-align: center;
`

export const HeroSubHeading = styled.h2`
  color: #d4d4d4;
  text-align: center;
`
