import React from "react"
import { Link } from "gatsby"

import { useGlobalContext } from "../context/context"
import { SidebarSubmenuLinksWrapper } from "../elements"

export default function SidebarSubmenu({ submenuLinks = [] }) {
  const { closeSidebar, toggleSubmenu } = useGlobalContext()
  return (
    <SidebarSubmenuLinksWrapper>
      {submenuLinks &&
        submenuLinks.map(({ kategorie, id }) => (
          <Link
            to={`/bilder/${kategorie.toLowerCase()}`}
            key={id}
            onClick={() => {
              closeSidebar()
              toggleSubmenu()
            }}
          >
            {kategorie}
          </Link>
        ))}
    </SidebarSubmenuLinksWrapper>
  )
}
