import styled from "styled-components"

export const SidebarWrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.sidebar.background};
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  box-shadow: ${(props) => props.theme.shadows.shadow2};
  transition: ${(props) => props.theme.animations.sidebar};
  transform: ${(props) =>
    props.isSidebarOpen ? "translate(0)" : "translate(100%)"};
  z-index: 20;
  overflow-y: scroll;
  overflow-x: hidden;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media screen and (min-width: 678px) {
    width: 400px;
  }
`

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 1.5rem;
`

export const SidebarLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  text-align: center;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.light1};
    font-weight: 500;
    font-size: 1.4rem;
    min-height: 2rem;
    padding: 2rem 0;

    &:hover {
      background-color: ${(props) => props.theme.colors.dark3};
    }
  }
`

export const SidebarFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SidebarFooterPolicyLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2em 0;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.dark1};
    font-weight: 400;
    font-size: 0.9rem;

    &:first-child {
      margin-right: 1rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

export const SidebarFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 1.5rem 1rem;

  a {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const SidebarSubmenuLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.light1};
    font-weight: 400;
    font-size: 1rem;
    padding: 0.5rem 0;
    width: 100%;
    margin-bottom: 1rem;

    &:hover {
      background-color: ${(props) => props.theme.colors.dark3};
    }
  }
`

export const LinkWithDropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.dark3};
  }
`
